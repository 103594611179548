import React, { useState,useEffect } from "react";
import { Col, Row, Modal, ModalBody, Input, Label, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { API_URL } from "../../../config";
import defaultUserImg from "../../../assets/images/user/img-02.jpg";
import { ToastContainer, toast } from 'react-toastify';


import { getEmployees2 } from "../../../services/userservice";


const CandidateGridDetails = () => {
  //Apply Now Model
  const [modal, setModal] = useState(false);
  const [employee,setEmployee] = useState([]);
  
  const openModal = () => setModal(!modal);
  
  useEffect(() => {
    const userRol = localStorage.getItem("rol");
    const employee_id = localStorage.getItem("employeeId");

    if(userRol !== '1'){
      toast.error("Por favor inicie sesión como empresa para continuar");
      return;
    }else if(employee_id){
      return;
    }else{
      getEmployees2()
      .then(data => setEmployee(data))
      .catch(error => console.error(error));
    }
  }, []);

 
  return (
    <React.Fragment>
      <ToastContainer />
      <Row className="align-items-center">
        <Col lg={8} md={7}>
          <div>
            <h6 className="fs-16 mb-0"> Mostrando 1 – 8 de 11 resultados </h6>
          </div>
        </Col>

        <Col lg={4} md={5}>
          <div className="candidate-list-widgets">
            <Row>
              <Col md={6}>
                <div className="selection-widget mt-3 mt-md-0">
                  <select
                    className="form-select"
                    data-trigger
                    name="choices-single-filter-orderby"
                    id="choices-single-filter-orderby"
                    aria-label="Default select example"
                  >
                    <option value="df">Default</option>
                    <option value="ne">Nuevas</option>
                    <option value="od">Antiguos</option>
                    <option value="rd">Random</option>
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="selection-widget mt-3 mt-md-0">
                  <select
                    className="form-select"
                    data-trigger
                    name="choices-candidate-page"
                    id="choices-candidate-page"
                    aria-label="Default select example"
                  >
                    <option value="all">Todos</option>
                    <option value="4">4 por Página</option>
                    <option value="8">8 por Página</option>
                    <option value="12">12 por Página</option>
                  </select>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="candidate-list">
        <Row>
          {employee.map((candidategridDetailsNew, key) => (
            <Col lg={4} md={6} key={key}>
              <div
                className={
                  candidategridDetailsNew.addclassNameBookmark === true
                    ? "candidate-grid-box bookmark-post card mt-4"
                    : "candidate-grid-box card mt-4"
                }
              >
                <CardBody className="p-4">
                  {candidategridDetailsNew.label && (
                    <div className="featured-label">
                      <span className="featured">
                        {"RECOMENDADO"}
                      </span>
                    </div>
                  )}

                  <div className="d-flex mb-4">
                    <div className="flex-shrink-0 position-relative">
                      <img
                        src={ `${API_URL}download2/${candidategridDetailsNew.photo}` }
                        alt=""
                        className="avatar-md rounded"
                        style={{ objectFit:"cover" }}
                        onError={(e) => { e.target.src = defaultUserImg; }}


                      />
                      <span
                        className={
                          candidategridDetailsNew.candidateStatusClassName
                        }
                      >
                        <span className="visually-hidden">activo</span>
                      </span>
                    </div>
                    <div className="ms-3">
                      <Link to="/candidatedetails"
                      onClick={() => localStorage.setItem("employee", candidategridDetailsNew.id)} className="primary-link">
                        <h5 className="fs-17">
                          {candidategridDetailsNew.name }
                          {candidategridDetailsNew.lastname}
                        </h5>
                      </Link>
                      <span className="fs-17">
                        Aspiración Salarial: {candidategridDetailsNew.salary}
                      </span>
                      
                    </div>
                  </div>
                  <ul className="list-inline d-flex justify-content-between align-items-center">
                    <li className="list-inline-item text-warning fs-17">
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star-half-full"></i>
                    </li>
                    <li className="list-inline-item">
                      <div className="favorite-icon">
                        <Link to="#">
                          <i className="uil uil-heart-alt fs-18"></i>
                        </Link>
                      </div>
                    </li>
                  </ul>
                  <div className="border rounded mb-4">
                    <div className="row g-0">
                      <Col lg={12}>
                        <div className="border-end px-3 py-2">
                          <p className="text-muted mb-0">
                            Tel. : {candidategridDetailsNew.phone}
                          </p>
                        </div>
                      </Col>
                      
                    </div>
                  </div>
                  <p className="text-muted">
                  {candidategridDetailsNew.description}
                  </p>
                  <div className="mt-3">
                    {/* <Link
                      to="#hireNow"
                      onClick={openModal}
                      data-bs-toggle="modal"
                      className="btn btn-primary btn-hover w-100 mt-2"
                    >
                      <i className="mdi mdi-account-check"></i> Contratar Ahora
                    </Link> */}
                    <Link
                      to="/candidatedetails"
                      onClick={() => localStorage.setItem("employee", candidategridDetailsNew.id)}
                      className="btn btn-soft-primary btn-hover w-100 mt-2"
                    >
                      <i className="mdi mdi-eye"></i> Ver Perfil
                    </Link>
                  </div>
                </CardBody>
              </div>
            </Col>
          ))}
        </Row>

        <div
          className="modal fade"
          id="hireNow"
          tabIndex="-1"
          aria-labelledby="hireNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Contratar Ahora
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="namrFormControlInput" className="form-label">
                    Nombre de la Empresa
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="namrFormControlInput"
                    placeholder="Nombre de la Empresa"
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailFormControlInput" className="form-label">
                    Email
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailFormControlInput"
                    placeholder="Email de contacto"
                  />
                </div>
                <div className="mb-4">
                  <Label
                    for="messageFormControlTextarea"
                    className="form-label"
                  >
                    Mensaje
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageFormControlTextarea"
                    rows="4"
                    placeholder="Escribe tu mensaje aquí"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Enviar
                </button>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateGridDetails;
