import React, { useState, useEffect } from "react";
import { Col, Row, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { getJobs, getCompany2, getCategoryById, getApplications, getJobOfferApplications, getEmployees3, saveJobOffer, delete_saved_offers_by_job_employee, get_saved_offers_by_employee_id } from "../../../services/userservice";
import { API_URL } from "../../../config";
import companyImg from "../../../assets/images/companyImg.jpg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobVacancy = ({ filters }) => {
  const [jobs, setJobs] = useState([]);
  const [companies, setCompanies] = useState({});
  const [categories, setCategories] = useState({});
  const [applications, setApplications] = useState([]);
  const [favoriteJobIds, setFavoriteJobIds] = useState([]);  // Para manejar ofertas guardadas
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedJobApplications, setSelectedJobApplications] = useState([]);

  const employee_id = localStorage.getItem("employeeId");

  useEffect(() => {
    getJobs()
      .then(data => {
        setJobs(data);
        // Fetch company and category information
        data.forEach(job => {
          getCompany2(job.company_id)
            .then(companyData => {
              setCompanies(prevState => ({
                ...prevState,
                [job.company_id]: companyData
              }));
            })
            .catch(error => console.error(error));

          getCategoryById(job.category_id)
            .then(categoryData => {
              setCategories(prevState => ({
                ...prevState,
                [job.category_id]: categoryData.category_name
              }));
            })
            .catch(error => console.error(error));
        });
      })
      .catch(error => console.error(error));

      getApplications()
        .then(data => {
          setApplications(data);
        })
        .catch(error => console.error(error));
        
    if (employee_id) {

      // Cargar ofertas guardadas
      get_saved_offers_by_employee_id(employee_id)
        .then(data => {
          const savedJobIds = data.map(offer => offer.job_offer_id);
          setFavoriteJobIds(savedJobIds);
        })
        .catch(error => console.error(error));
    }
  }, [employee_id]);

  const filterJobs = (jobs) => {
    return jobs.filter(job => {
      if (job.suspended || !job.status) return false;

      const company = companies[job.company_id] || {};
      const category = categories[job.category_id] || '';
      const matchesCompanyName = company.name?.toLowerCase().includes(filters.companyName.toLowerCase()) || false;
      const matchesLocation = company.country?.toLowerCase().includes(filters.location.toLowerCase());
      const matchesJobType = category.toLowerCase().includes(filters.jobType.toLowerCase());
      return matchesCompanyName && matchesLocation && matchesJobType;
    });
  };

  const filteredJobs = filterJobs(jobs);

  const getApplicationCount = (jobOfferId) => {
    return applications.filter(app => app.job_offer_id === jobOfferId).length;
  };

  const openModal = async (jobTitle, employee_list) => {
    setModalTitle(jobTitle);

    const employeeDetailsList = await Promise.all(
      employee_list.map(async (employee) => {
        const employeeDetails = await getEmployees3(employee.employee_id);
        return {
          user_name: employeeDetails.name,
          user_last_name: employeeDetails.lastname,
          user_id: employeeDetails.id
        };
      })
    );

    setSelectedJobApplications(employeeDetailsList);
    setModal(true);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleClickPostulaciones = (jobVacancyDetails) => {
    getJobOfferApplications(jobVacancyDetails.id)
      .then(data => openModal(jobVacancyDetails.title, data))
      .catch(error => console.log(error));
  };

  // Manejar favoritos
  const handleFavoriteToggle = (jobOfferId) => {
    const user = localStorage.getItem("id");
    if (user === null) {
      toast.error("Por favor inicie sesión para continuar");
      return;
    }
    if (favoriteJobIds.includes(jobOfferId)) {
      // Si ya es favorito, eliminarlo
      delete_saved_offers_by_job_employee(jobOfferId, employee_id)
        .then(() => {
          setFavoriteJobIds(favoriteJobIds.filter(id => id !== jobOfferId));
          toast.success("Oferta eliminada de favoritos");
        })
        .catch(error => {
          toast.error("Error al eliminar de favoritos");
          console.error(error);
        });
    } else {
      // Si no es favorito, agregarlo
      const saveJobData = { job_offer_id: jobOfferId, employee_id: employee_id };
      saveJobOffer(saveJobData)
        .then(() => {
          setFavoriteJobIds([...favoriteJobIds, jobOfferId]);
          toast.success("Oferta agregada a favoritos");
        })
        .catch(error => {
          toast.error("Error al agregar a favoritos");
          console.error(error);
        });
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <style>
        {`
          .image-container {
            width: 157px;
            height: 87px;
            overflow: hidden;
            position: relative;
          }

          .image-container img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
          }

          .favorite-icon i.red {
            color: red;
          }

          .clickable-text {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
          }
        `}
      </style>
      {filteredJobs.map((jobVacancyDetails, key) => (
        <div
          key={key}
          className={
            jobVacancyDetails.addclassNameBookmark === true
              ? "job-box bookmark-post card mt-4"
              : "job-box card mt-4"
          }
        >
          <div className="p-4">
            <Row>
              <Col lg={2}>
                <Link to="/companydetails">
                  <div className="image-container">
                    <img
                      className="img-fluid rounded-3"
                      src={companies[jobVacancyDetails.company_id]?.photo ? `${API_URL}download2/${companies[jobVacancyDetails.company_id]?.photo}` : companyImg}
                      alt=""
                      onError={(e) => { e.target.src = companyImg; }}
                    />
                  </div>
                </Link>
              </Col>
              <Col lg={10}>
                <div className="mt-3 mt-lg-0">
                  <h5 className="fs-17 mb-1">
                    <Link onClick={() => localStorage.setItem("jobs", jobVacancyDetails.id)} to="/jobdetails" className="text-dark">
                      {jobVacancyDetails.title}
                    </Link>{" "}
                    <small className="text-muted fw-normal">
                      ({categories[jobVacancyDetails.category_id]})
                    </small>
                  </h5>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        {jobVacancyDetails.description}
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        <i className="mdi mdi-map-marker"></i>{" "}
                        {jobVacancyDetails.city}
                      </p>
                    </li>
                    <li className="list-inline-item">
                      <p className="text-muted fs-14 mb-0">
                        <i className="uil uil-wallet"></i>{" "}
                        {jobVacancyDetails.salary}
                      </p>
                    </li>
                  </ul>
                  <div className="mt-2">
                    <span
                      className={
                        jobVacancyDetails.fullTime === true
                          ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                          : jobVacancyDetails.partTime === true
                          ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                          : jobVacancyDetails.freeLance === true
                          ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                          : jobVacancyDetails.internship === true
                          ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                          : ""
                      }
                    >
                      {jobVacancyDetails.timing}
                    </span>
                  </div>
                  <div className="mt-2">
                    <span className="text-muted fs-13">Publicado el: {new Date(jobVacancyDetails.created_at).toLocaleDateString()}</span>
                  </div>
                  <div className="mt-2">
                    <span className="text-muted fs-13 clickable-text" onClick={ () => {handleClickPostulaciones(jobVacancyDetails)}}>
                      Postulaciones: {getApplicationCount(jobVacancyDetails.id)}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="favorite-icon">
              <Link
                to="#jobGrid"
                onClick={() => handleFavoriteToggle(jobVacancyDetails.id)}
              >
                <i className={`mdi ${favoriteJobIds.includes(jobVacancyDetails.id) ? 'mdi-cards-heart red' : 'mdi-heart-outline'}`}></i>
              </Link>
            </div>
          </div>
          <div className="p-3 bg-light">
            <Row className="justify-content-between">
              <Col md={8}>
                <div>
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <i className="uil uil-tag"></i> Keywords :
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="primary-link text-muted">
                        Diseño
                      </Link>
                      ,
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="primary-link text-muted">
                        Desarrollador
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col md={4}>
                <div className="text-md-end">
                  <Link onClick={() => localStorage.setItem("jobs", jobVacancyDetails.id)} to="/jobdetails" className="primary-link">
                    Aplicar Ahora <i className="mdi mdi-chevron-double-right"></i>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ))}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Postulaciones para {modalTitle}</ModalHeader>
        <ModalBody>
          {selectedJobApplications.length > 0 ? (
            <ul>
              {selectedJobApplications.map((application, index) => (
                <Link
                  to="/candidatedetails"
                  onClick={() => localStorage.setItem("employee", application.user_id)}
                  key={index}
                >
                  <li>{application.user_name} {application.user_last_name}</li>
                </Link>
              ))}
            </ul>
          ) : (
            <p>No hay postulaciones para este empleo.</p>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default JobVacancy;
